// custom typefaces
import 'typeface-montserrat';
import 'typeface-merriweather';

// import React from 'react';

require('prismjs/themes/prism.css');
require('katex/dist/katex.min.css');

// export const wrapRootElement = ({ element }) => {
// 	return <SessionCheck>{element}</SessionCheck>;
// };
